import { useSelector } from 'react-redux';
import { Input } from '../../../shared/components/input/Input';
import {
    interviewPreparationSpecializationSelector,
    interviewPreparationSpecializationsSelector,
} from '../core/interviewPreparationSelectors';
import { SelectOption } from '../../../shared/components/selectOption/SelectOption';
import { useAppDispatch } from '../../../app/hooks';
import {
    searchSpecializationAction,
    selectSpecializationAction,
} from '../core/interviewPreparationSlice';
import { FormEventHandler, useEffect } from 'react';
import './SpecializationsList.scss'
import {ReactComponent as SearchIcon} from '../../../shared/components/icons/Search.svg'

export const SpecializationsList = () => {
    const dispatch = useAppDispatch();

    const specializations = useSelector(
        interviewPreparationSpecializationsSelector
    );
    const specialization = useSelector(
        interviewPreparationSpecializationSelector
    );

    const onSelect = (id: number) => {
        dispatch(selectSpecializationAction(id));
    };

    const onInput: FormEventHandler<{}> = (event) => {
        dispatch(
            searchSpecializationAction((event.target as HTMLInputElement).value)
        );
    };

    const onChange: FormEventHandler<{}> = (event) => {
        dispatch(
            searchSpecializationAction((event.target as HTMLInputElement).value)
        );
    };

    useEffect(() => {
        return () => {
            dispatch(searchSpecializationAction(''));
        };
    }, [])

    return (
        <div className="specializations-list">
            <Input
                placeholder="Поиск профессии..."
                onInput={onInput}
                onChange={onChange}
                iconElement={<SearchIcon />}
                style={{background: 'transparent'}}
            />
            {specializations.map((i) => (
                <SelectOption
                    key={i.id}
                    selected={i.id === specialization?.id}
                    title={i.profession_name}
                    subtitle={i.description}
                    onSelect={() => onSelect(i.id)}
                />
            ))}
        </div>
    );
};
