import { apiClient } from "../../../@core/api/client"

export type QuestionResponse = {
  question_id: number;
  answer_id: number;
  question: string;
}

export const interviewApi = {
  getQuestion: async (interviewId: number) => {
    const res = await apiClient.get<QuestionResponse>(`/interview/${interviewId}/question/`);
    return res.data;
  },
  getQuestionAudio: async (questionId: number) => {
    const res = await apiClient.get<Blob>(
      `/interview/question/${questionId}/audio/`,
      { responseType: 'blob' }
    );
    return res.data;
  },
  submitAnswer: async (answerId: number, formData?: FormData) => {
    const res = await apiClient.post<{status: 0 | 1}>(
      `/interview/answer/${answerId}/`, 
      formData,
      {headers: { "Content-Type": "multipart/form-data" }},
    );
    return res.data;
  },
}