import { createSlice } from '@reduxjs/toolkit';
import { ApiStatusType } from '../../../@core/types/apiStatusType';
import {
    DifficultyModel,
    SpecializationModel,
} from './interviewPreparationModels';
import {
    availableDifficulties,
    steps,
} from './interviewPreparationStaticValues';
import { getSpecializationsThunk, startInterviewThunk } from './interviewPreparationThunks';
import { Step } from '../../../shared/components/stepper/Stepper';

export type InterviewPreparationSliceState = {
    status: ApiStatusType;
    interviewId: number | null;
    stepId: number;
    steps: Step[];
    specializationQuery: string;
    availableSpecialization: SpecializationModel[];
    availableDifficulties: DifficultyModel[];
    specialization: SpecializationModel | null;
    difficulty: DifficultyModel | null;
};

const initialState: InterviewPreparationSliceState = {
    status: ApiStatusType.IDLE,
    interviewId: null,
    stepId: 0,
    steps: steps,
    specializationQuery: '',
    availableSpecialization: [],
    availableDifficulties: availableDifficulties,
    specialization: null,
    difficulty: null,
};

export const interviewPreparationSlice = createSlice({
    name: 'interviewPreparation',
    initialState,
    reducers: {
        switchStep: (state, { payload }: { payload: number }) => {
            if (state.stepId < 0) {
                return;
            }
            if (state.stepId >= state.steps.length) {
                return;
            }
            state.stepId = payload;
        },
        nextStep: (state) => {
            if (state.stepId >= state.steps.length) {
                return;
            }
            state.stepId += 1;
        },
        previousStep: (state) => {
            if (state.stepId < 0) {
                return;
            }
            state.stepId -= 1;
        },
        searchSpecialization: (
            state,
            { payload: query }: { payload: string }
        ) => {
            state.specializationQuery = query;
        },
        selectSpecialization: (state, { payload: id }: { payload: number }) => {
            state.specialization =
                state.availableSpecialization.find((i) => i.id === id) || null;
        },
        selectDifficulty: (state, { payload: level }: { payload: number }) => {
            state.difficulty =
                state.availableDifficulties.find((i) => i.level === level) ||
                null;
        },
        clearState: (state) => {
            state.stepId = 0;
            state.difficulty = null;
            state.interviewId = null;
            state.specialization = null;
            state.specializationQuery = '';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSpecializationsThunk.pending, (state, action) => {
                state.status = ApiStatusType.PENDING;
            })
            .addCase(getSpecializationsThunk.fulfilled, (state, action) => {
                state.status = ApiStatusType.SUCCESS;
                state.availableSpecialization = action.payload;
            })
            .addCase(startInterviewThunk.pending, (state, action) => {
                state.status = ApiStatusType.PENDING;
            })
            .addCase(startInterviewThunk.fulfilled, (state, { payload }) => {
                state.status = ApiStatusType.SUCCESS;
                state.interviewId = payload.interview_id;
            });
    },
});

export const switchStepAction = interviewPreparationSlice.actions.switchStep;
export const nextStepAction = interviewPreparationSlice.actions.nextStep;
export const previousStepAction =
    interviewPreparationSlice.actions.previousStep;
export const searchSpecializationAction =
    interviewPreparationSlice.actions.searchSpecialization;
export const selectSpecializationAction =
    interviewPreparationSlice.actions.selectSpecialization;
export const selectDifficultyAction =
    interviewPreparationSlice.actions.selectDifficulty;
export const clearStateAction =
    interviewPreparationSlice.actions.clearState;
