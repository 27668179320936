import { Header } from './features/header/Header';
import { AppRoutes } from './AppRoutes';
import './App.scss';
import { useAppDispatch, useAppSelector } from './app/hooks';
import {
    selectCSRFToken,
    selectCurrentAuthInProgress,
    selectCurrentAuthStatus,
    selectIsAuth,
} from './features/auth/core/authSelectors';
import { useEffect } from 'react';
import { getCSRFThunk, getUserThunk } from './features/auth/core/authThunks';
import { useNavigate } from 'react-router-dom';
import { ApiStatusType } from './@core/types/apiStatusType';

const App = () => {
    const isAuth = useAppSelector(selectIsAuth);
    const isAuthInProgress = useAppSelector(selectCurrentAuthInProgress);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const authorizedClassName = isAuth ? 'app-authorized' : 'app-unauthorized';

    useEffect(() => {
        dispatch(getCSRFThunk());
        dispatch(getUserThunk());
    }, [])

    useEffect(() => {
        if (
            isAuth &&
            !isAuthInProgress &&
            window.location.pathname.includes('/auth/')
        ) {
            navigate('/interviews/');
        }
        if (
            !isAuth &&
            !isAuthInProgress &&
            !window.location.pathname.includes('/auth/')
        ) {
            navigate('/auth/');
        }
    }, [isAuthInProgress, isAuth]);

    return !isAuthInProgress ? (
        <div className={`app ${authorizedClassName}`}>
            {isAuth && <Header />}
            <AppRoutes />
        </div>
    ) : <>Loading....</>;
};

export default App;
