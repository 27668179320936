import { Step } from '../../../shared/components/stepper/Stepper';
import { DifficultyModel } from './interviewPreparationModels';

export const availableDifficulties: DifficultyModel[] = [
    {
        level: 1,
        title: 'Junior',
    },
    {
        level: 2,
        title: 'Junior+',
    },
    {
        level: 3,
        title: 'Middle',
    },
    {
        level: 4,
        title: 'Middle+',
    },
    {
        level: 5,
        title: 'Senior',
    },
];

export const steps: Step[] = [
    {
        title: 'Выбор профессии',
        subtitle: 'Выберите профессию, по которой хотите пройти собеседование',
    },
    {
        title: 'Выбор сложности',
        subtitle: 'Выберите сложность собеседования',
    },
];
