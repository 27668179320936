import { createAsyncThunk } from '@reduxjs/toolkit';
import { authApi } from './authApi';
import { LoginData, SignUpData } from './authModels';

export const getUserThunk = createAsyncThunk('auth/getUser', async () => {
    const res = await authApi.getUser();
    return res.data;
});

export const loginThunk = createAsyncThunk(
    'auth/login',
    async (data: LoginData) => {
        await authApi.login(data);
        await getUserThunk();
    }
);

export const signupThunk = createAsyncThunk(
    'auth/signup',
    async (data: SignUpData) => {
        const res = await authApi.signup(data);
        return res.data;
    }
);

export const logoutThunk = createAsyncThunk('auth/logout', async () => {
    const res = await authApi.logout();
    return res.data;
});

export const getCSRFThunk = createAsyncThunk('auth/getCSRF', async () => {
    return await authApi.getCSRFToken();
});
