import { Button } from '../../shared/components/button/Button';
import { Input } from '../../shared/components/input/Input';
import './AuthPage.scss';
import { SignUpData } from './core/authModels';
import { signupThunk } from './core/authThunks';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectCurrentAuthStatus,
    selectCurrentAuthStatusMessage,
} from './core/authSelectors';
import { ApiStatusType } from '../../@core/types/apiStatusType';
import { useForm } from 'react-hook-form';
import { useApiStatus } from '../../shared/hooks/useApiStatus';

export const Signup = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const status = useAppSelector(selectCurrentAuthStatus);
    const message = useAppSelector(selectCurrentAuthStatusMessage);

    useApiStatus({
        status,
        handleSuccess: () => navigate('/auth/login'),
        handleFailed: () => console.error(message),
    });

    const { register, handleSubmit } = useForm<SignUpData>();

    const onSubmit = (data: SignUpData) => {
        dispatch(signupThunk(data));
    };

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Input placeholder="Ник" name="username" register={register} />
            <Input placeholder="Email" name="email" register={register} />
            <Input
                type="password"
                name="password"
                placeholder="Пароль"
                register={register}
            />
            <Button
                type="submit"
                styleType="positive"
                style={{ marginTop: '8px' }}
                disabled={status === ApiStatusType.PENDING}>
                Зарегистрироваться
            </Button>
            <Button
                type="button"
                styleType="default"
                onClick={() => navigate('/auth/login')}>
                Войти
            </Button>
        </form>
    );
};
