import { apiClient } from '../../../@core/api/client';
import { SpecializationModel } from './interviewPreparationModels';
import { StartInterviewData } from './interviewPreparationTypes';

export const interviewApi = {
    getSpecializations: async () => {
        return await apiClient.get<SpecializationModel[]>(
            '/interview/specialization_list/'
        );
    },
    startInterview: async (data: StartInterviewData) => {
        return await apiClient.post('/interview/start/', data)
    },
};
