import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { authSlice } from '../features/auth/core/authSlice';
import { interviewPreparationSlice } from '../features/interviewPreparation/core/interviewPreparationSlice';
import { interviewResultSlice } from '../features/interviewResult/core/interviewResultSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    interviewResult: interviewResultSlice.reducer,
    interviewPreparation: interviewPreparationSlice.reducer,
  }
});

// Infer the type of `store`
export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore['dispatch'];
// Define a reusable type describing thunk functions
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>;