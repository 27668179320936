import { Stepper } from '../../shared/components/stepper/Stepper';
import {
    interviewPreparationDifficultySelector,
    interviewPreparationInterviewIdSelector,
    interviewPreparationSpecializationSelector,
    interviewPreparationStepIdSelector,
    interviewPreparationStepSelector,
} from './core/interviewPreparationSelectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { steps } from './core/interviewPreparationStaticValues';
import {
    clearStateAction,
    nextStepAction,
    previousStepAction,
    switchStepAction,
} from './core/interviewPreparationSlice';
import { Button } from '../../shared/components/button/Button';
import './InterviewPreparationPage.scss';
import { SpecializationsList } from './components/SpecializationsList';
import { useEffect } from 'react';
import {
    getSpecializationsThunk,
    startInterviewThunk,
} from './core/interviewPreparationThunks';
import { DifficultiesList } from './components/DifficultiesList';
import { useNavigate } from 'react-router-dom';

export const InterviewPreparationPage = () => {
    const step = useAppSelector(interviewPreparationStepSelector);
    const stepId = useAppSelector(interviewPreparationStepIdSelector);
    const difficulty = useAppSelector(interviewPreparationDifficultySelector);
    const specialization = useAppSelector(
        interviewPreparationSpecializationSelector
    );
    const interviewId = useAppSelector(interviewPreparationInterviewIdSelector);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onSwitch = (step: number) => {
        dispatch(switchStepAction(step));
    };

    const handleNext = () => {
        dispatch(nextStepAction());
    };

    const handlePrevious = () => {
        dispatch(previousStepAction());
    };

    const handleStartInterview = () => {
        if (!difficulty || !specialization) {
            return;
        }
        dispatch(
            startInterviewThunk({
                specialization_id: specialization.id,
                difficulty: difficulty.level,
            })
        );
    };

    useEffect(() => {
        dispatch(getSpecializationsThunk());
    }, []);

    useEffect(() => {
        return () => {
            dispatch(clearStateAction());
        };
    }, []);

    useEffect(() => {
        if (interviewId !== null) {
            navigate(`/interview/${interviewId}/`);
        }
    }, [interviewId]);

    const buttons: Record<number, JSX.Element> = {
        0: (
            <Button
                styleType="positive"
                style={{ width: '300px' }}
                onClick={handleNext}>
                Выбор уровня сложности
            </Button>
        ),
        1: (
            <>
                <Button
                    styleType="grey"
                    style={{ width: '300px' }}
                    onClick={handlePrevious}>
                    Вернуться к выбору профессии
                </Button>
                <Button
                    styleType="positive"
                    style={{ width: '300px' }}
                    onClick={handleStartInterview}>
                    Начать собеседование
                </Button>
            </>
        ),
    };

    const component: Record<number, JSX.Element> = {
        0: <SpecializationsList />,
        1: <DifficultiesList />,
    };

    return (
        <div className="interview-preparation">
            <Stepper steps={steps} current={step} onSwitch={onSwitch}>
                {buttons[stepId]}
            </Stepper>
            <div className="interview-preparation-content">
                {component[stepId]}
            </div>
        </div>
    );
};
