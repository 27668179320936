import { useAppSelector } from '../../app/hooks';
import { ReactComponent as Logo } from '../../shared/components/icons/Logo-32.svg';
import { selectCurrentUserName } from '../auth/core/authSelectors';
import './Header.scss';

export const Header = () => {
    const userName = useAppSelector(selectCurrentUserName);

    return (
        <div className="header">
            <Logo />
            <div className="user-info">{userName}</div>
        </div>
    );
};
