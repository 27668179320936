import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getInterviewResultThunk } from './core/interviewResultThunks';
import { selectInterviewResult } from './core/interviewResultSelectors';
import { Accordion } from '../../shared/components/accordion/Accordion';
import './InterviewResultPage.scss';

export const InterviewResultPage = () => {
    const { id: interviewId } = useParams<{ id: string }>();

    const dispatch = useAppDispatch();
    const result = useAppSelector(selectInterviewResult);

    useEffect(() => {
        if (interviewId === null) {
            return;
        }
        dispatch(getInterviewResultThunk(Number(interviewId)));
    }, []);

    return (
        <div className="interview-result">
            <div className="interview-result-title">
                {String(result?.specialization)}
            </div>
            <div className="interview-result-answers">
                {result?.answers?.map((i) => (
                    <Accordion
                        key={i.question}
                        accordionTitle={i.question}
                        score={String(i.score)}>
                        <div className="result-answer-t">Ответ: {i.answer}</div>
                        <div className="result-answer-t">
                            Ваш твет: {i.user_answer}
                        </div>
                    </Accordion>
                ))}
            </div>
        </div>
    );
};
