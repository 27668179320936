import { useNavigate } from 'react-router-dom';
import { Button } from '../../shared/components/button/Button';
import './InterviewsPage.scss';

export const InterviewsPage = () => {
    const navigate = useNavigate();

    const onClick = (event: any) => {
        navigate('/interview/preparation')
    };

    return (
        <div className="interviews">
            <div className="empty-status-container">
                <div className="header-title">
                    Пока тут нет собеседование. Начать первое собеседование?
                </div>
                <Button styleType="positive" onClick={onClick}>
                    Перейти к собеседованию
                    {/* todo: add icon */}
                </Button>
            </div>
        </div>
    );
};
