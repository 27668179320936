import { ReactNode } from 'react';
import './Stepper.scss';

export type Step = {
    title: string;
    subtitle: string;
};

export const Stepper: React.FC<{
    children?: ReactNode;
    steps: Step[];
    current: Step;
    onSwitch: (step: number) => void;
}> = ({ steps, current, children, onSwitch }) => {
    return (
        <div className="stepper">
            <div className="stepper-list">
                {steps.map((i, id) => (
                    <div
                        key={i.title}
                        className={`stepper-item ${i === current && 'active'}`}
                        onClick={() => onSwitch(id)}>
                        <div className="item-title">{i.title}</div>
                    </div>
                ))}
            </div>
            <div className="stepper-title">{current.title}</div>
            <div className="stepper-subtitle">{current.subtitle}</div>
            <div className="stepper-buttons">{children}</div>
        </div>
    );
};
