import { createSlice } from '@reduxjs/toolkit';
import { ApiStatusType } from '../../../@core/types/apiStatusType';
import { InterviewResult } from './interviewResultTypes';
import { getInterviewResultThunk } from './interviewResultThunks';

export type InterviewPreparationSliceState = {
    status: ApiStatusType;
    result: InterviewResult | null;
};

const initialState: InterviewPreparationSliceState = {
    status: ApiStatusType.IDLE,
    result: null,
};

export const interviewResultSlice = createSlice({
    name: 'interviewResult',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getInterviewResultThunk.pending, (state) => {
                state.status = ApiStatusType.PENDING;
            })
            .addCase(getInterviewResultThunk.fulfilled, (state, { payload }) => {
                state.status = ApiStatusType.IDLE;
                state.result = payload;
            });
    },
});
