import {
    FocusEventHandler,
    InputHTMLAttributes,
    MouseEventHandler,
    useRef,
    useState,
} from 'react';
import './Input.scss';
import { UseFormRegister } from 'react-hook-form';

export type InputOwnProps = {
    iconElement?: JSX.Element;
    register?: UseFormRegister<any>;
};

export type InputProps = InputHTMLAttributes<{}> & InputOwnProps;

export const Input = ({ iconElement, register, ...props }: InputProps) => {
    const [hovered, setHovered] = useState(false);
    const [focused, setFocused] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const onMouseEnter: MouseEventHandler<{}> = (event) => {
        setHovered(true);
        props.onMouseEnter?.(event);
    };

    const onMouseLeave: MouseEventHandler<{}> = (event) => {
        setHovered(false);
        props.onMouseLeave?.(event);
    };

    const onFocus: FocusEventHandler<{}> = (event) => {
        setFocused(true);
        props.onFocus?.(event);
    };

    const onBlur: FocusEventHandler<{}> = (event) => {
        setFocused(false);
        props.onBlur?.(event);
    };

    const getInputStyle = () => ({
        width: (ref?.current?.offsetWidth || 0) - 32 + 'px',
    });

    return (
        <div
            className={`input-container ${hovered && 'hover'} ${focused && 'focus'}`}
            style={props.style}>
            {iconElement && iconElement}
            <div className="input-container-inside" ref={ref}>
                <input
                    {...props}
                    {...(register ? register(props.name || '') : null)}
                    style={{ ...getInputStyle() }}
                    autoComplete="off"
                    className="input"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            </div>
        </div>
    );
};
