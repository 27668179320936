import { useNavigate } from 'react-router-dom';
import { Button } from '../../shared/components/button/Button';
import { Input } from '../../shared/components/input/Input';
import './AuthPage.scss';
import { useForm } from 'react-hook-form';
import { LoginData } from './core/authModels';
import { loginThunk } from './core/authThunks';
import { useAppDispatch } from '../../app/hooks';

export const Login = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { register, handleSubmit } = useForm<LoginData>();

    const onSubmit = (data: LoginData) => {
        dispatch(loginThunk(data));
        navigate('/interviews/')
    };

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Input placeholder='Ник или email' name="username_or_email" register={register} />
            <Input type="password" placeholder='Пароль' name="password" register={register} />
            <Button type="submit" styleType="positive">
                Войти
            </Button>
            <Button
                type="button"
                styleType="default"
                onClick={() => navigate('/auth/signup')}>
                Вернуться к регистрации
            </Button>
        </form>
    );
};
