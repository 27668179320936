import { useEffect, useRef, useState } from 'react';

export const useRecorder = (mimeType: string = 'audio/webm') => {
    const [permission, setPermission] = useState(false);
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
    const [audio, setAudio] = useState<Blob | null>(null);
    const mediaRecorder = useRef<MediaRecorder | null>(null);

    const getMicrophonePermission = async () => {
        if ('MediaRecorder' in window) {
            const streamData = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false,
            });
            setPermission(true);
            setStream(streamData);
        }
    };

    const startRecording = async () => {
        if (!stream) {
            return;
        }
        const media = new MediaRecorder(stream, { mimeType });
        mediaRecorder.current = media;
        mediaRecorder.current.start();
        const localAudioChunks: Blob[] = [];
        mediaRecorder.current.ondataavailable = (event) => {
            if (typeof event.data === 'undefined') {
                return;
            }
            if (event.data.size === 0) {
                return;
            }
            localAudioChunks.push(event.data);
        };
        setAudioChunks(localAudioChunks);
    };

    const stopRecording = () => {
        if (!mediaRecorder.current) {
            return;
        }
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: mimeType });
            setAudio(audioBlob);
            setAudioChunks([]);
        };
        setAudio(null);
        mediaRecorder.current = null;
    };
    

    useEffect(() => {
        getMicrophonePermission();
    }, []);

    const clear = () => {
        setStream(null);
        setAudio(null);
        setAudioChunks([]);
        mediaRecorder.current = null;
    };

    return {
        stream,
        mediaRecorder: mediaRecorder.current,
        file: audio,
        startRecording,
        stopRecording,
        clear
    };
};
