import { RootState } from '../../../app/store';

export const interviewPreparationInterviewIdSelector = (state: RootState) => {
    return state.interviewPreparation.interviewId;
};
export const interviewPreparationStepSelector = (state: RootState) => {
    return state.interviewPreparation.steps[state.interviewPreparation.stepId];
};

export const interviewPreparationStepIdSelector = (state: RootState) => {
    return state.interviewPreparation.stepId;
};
export const interviewPreparationStepsSelector = (state: RootState) => {
    return state.interviewPreparation.steps;
};
export const interviewPreparationSpecializationSelector = (
    state: RootState
) => {
    return state.interviewPreparation.specialization;
};
export const interviewPreparationDifficultiesSelector = (state: RootState) => {
    return state.interviewPreparation.availableDifficulties;
};
export const interviewPreparationDifficultySelector = (state: RootState) => {
    return state.interviewPreparation.difficulty;
};
export const interviewPreparationSpecializationsSelector = (
    state: RootState
) => {
    const { specializationQuery: query } = state.interviewPreparation;
    return query ? state.interviewPreparation.availableSpecialization.filter((i) => {
        return (
            i.category_name?.startsWith(query) ||
            i.description?.startsWith(query) ||
            i.profession_name?.startsWith(query) ||
            i.programming_language?.name.startsWith(query)
        );
    }) : state.interviewPreparation.availableSpecialization;
};
