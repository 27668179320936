import { createAsyncThunk } from '@reduxjs/toolkit';
import { interviewApi } from './interviewPreparationApi';
import { SpecializationModel } from './interviewPreparationModels';
import { StartInterviewData } from './interviewPreparationTypes';

export const getSpecializationsThunk = createAsyncThunk(
    'interview/getSpecializations',
    async () => {
        const res = await interviewApi.getSpecializations();
        return res.data;
    }
);

export const startInterviewThunk = createAsyncThunk(
    'interview/startInterview',
    async (data: StartInterviewData) => {
        const res = await interviewApi.startInterview(data);
        return res.data as {interview_id: number};
    }
);
