import { AudioVisualizer, LiveAudioVisualizer } from 'react-audio-visualize';
import { Button } from '../../shared/components/button/Button';
import { useEffect, useRef, useState } from 'react';
import './InterviewPage.scss';
import { useRecorder } from './utils/useRecorder';
import { useNavigate, useParams } from 'react-router-dom';
import { interviewApi } from './core/interviewApi';

export const InterviewPage = () => {
    const navigate = useNavigate();

    const { id: interviewId } = useParams<{ id: string }>();
    const { mediaRecorder, startRecording, stopRecording, file, clear } =
        useRecorder();

    const [answerId, setAnswerId] = useState<number | null>(null);
    const [questionAudio, setQuestionAudio] = useState<string | null>(null);
    const [questionAudioBlob, setQuestionAudioBlob] = useState<Blob | null>(
        null
    );
    const [audioTime, setAudioTime] = useState<number | undefined>();

    const audioElement = useRef<HTMLAudioElement | null>(null);

    const getQuestion = () => {
        interviewApi.getQuestion(Number(interviewId)).then((q) => {
            setAnswerId(q.answer_id);
            interviewApi.getQuestionAudio(q.question_id).then((audio) => {
                setQuestionAudioBlob(audio);
                setQuestionAudio(URL.createObjectURL(audio));
            });
        });
    };

    const sendAnswer = () => {
        stopRecording();
    };

    const handleFileUpdate = () => {
        if (answerId === null || file === null) {
            return;
        }
        const formData = new FormData();
        formData.append('audio_file', file);
        handleSubmitAnswer(answerId, formData);
    };

    const skipAnswer = () => {
        if (answerId === null) {
            return;
        }
        stopRecording();
        handleSubmitAnswer(answerId);
    };

    const handleSubmitAnswer = (answerId: number, formData?: FormData) => {
        interviewApi
            .submitAnswer(answerId, formData)
            .then(({ status }) => {
                if (status === 0) {
                    navigate(`/interview/result/${interviewId}`);
                    return;
                }
                getQuestion();
            });
    };

    const onAudioEnded = () => {
        setQuestionAudio(null);
        startRecording();
    };

    const onTimeUpdate = () => {
        setAudioTime(audioElement!.current!.currentTime);
    };

    useEffect(() => {
        getQuestion();
    }, []);

    useEffect(handleFileUpdate, [file]);

    useEffect(() => {
        return () => {
            clear();
        }
    }, [])

    return (
        <div className="interview">
            {mediaRecorder && (
                <LiveAudioVisualizer
                    mediaRecorder={mediaRecorder}
                    width={340}
                    height={200}
                    barColor="#3656F6"
                    barWidth={4}
                    gap={8}
                    fftSize={1024}
                    smoothingTimeConstant={0.4}
                    minDecibels={-90}
                />
            )}
            {questionAudio && questionAudioBlob && (
                <>
                    <AudioVisualizer
                        blob={questionAudioBlob}
                        width={340}
                        height={200}
                        barColor="#3656F6"
                        barPlayedColor="#4b6fff"
                        barWidth={1}
                        gap={2}
                        currentTime={audioTime}
                    />
                    <audio
                        ref={audioElement}
                        style={{ display: 'none' }}
                        src={questionAudio}
                        autoPlay
                        onEnded={onAudioEnded}
                        onTimeUpdate={onTimeUpdate}
                    />
                </>
            )}
            {!questionAudio && !questionAudioBlob && !mediaRecorder && (
                <div style={{ minWidth: '320px', minHeight: '200px', display: 'flex' }}></div>
            )}
            <div className="interview-buttons">
                <Button styleType="positive" onClick={sendAnswer}>
                    Отправить голосовой ответ
                </Button>
                <Button styleType="grey" onClick={skipAnswer}>
                    Пропустить вопрос
                </Button>
                <Button styleType="negative">Завершить собеседование</Button>
            </div>
        </div>
    );
};
