import { useSelector } from 'react-redux';
import {
  interviewPreparationDifficultiesSelector,
    interviewPreparationDifficultySelector,
} from '../core/interviewPreparationSelectors';
import { SelectOption } from '../../../shared/components/selectOption/SelectOption';
import { useAppDispatch } from '../../../app/hooks';
import { selectDifficultyAction } from '../core/interviewPreparationSlice';
import './DifficultiesList.scss'

export const DifficultiesList = () => {
    const dispatch = useAppDispatch();

    const difficulties = useSelector(
        interviewPreparationDifficultiesSelector
    );
    const difficulty = useSelector(
      interviewPreparationDifficultySelector
    );

    const onSelect = (id: number) => {
        dispatch(selectDifficultyAction(id));
    };

    return (
        <div className="difficulties-list">
            {difficulties.map((i) => (
                <SelectOption
                    key={i.level}
                    selected={i.level === difficulty?.level}
                    title={i.title}
                    onSelect={() => onSelect(i.level)}
                />
            ))}
        </div>
    );
};