import { Outlet } from 'react-router-dom';
import './AuthPage.scss';
import { ReactComponent as Logo } from '../../shared/components/icons/Logo-64.svg';

export const AuthPage = () => {
    return (
        <div className="auth-page">
            <div className="image-viewer"></div>
            <div className="content">
                <Logo />
                <Outlet />
            </div>
        </div>
    );
};
