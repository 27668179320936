import { ButtonHTMLAttributes } from 'react';
import './Button.scss';

export type ButtonType = 'default' | 'grey' | 'positive' | 'negative';

export type ButtonOwnProps = {
    styleType?: ButtonType;
    iconElement?: JSX.Element;
};

export type ButtonProps = ButtonHTMLAttributes<{}> & ButtonOwnProps;

export const Button = ({ styleType, iconElement, ...props }: ButtonProps) => {
    return (
        <button
            {...props}
            className={'button button-' + (styleType || 'default')}>
            {iconElement && iconElement}
            {props.children}
        </button>
    );
};
