import { HTMLAttributes, useState } from 'react';
import { ReactComponent as ChevronTop } from '../icons/ChevronTop.svg';
import { ReactComponent as ChevronBottom } from '../icons/ChevronBottom.svg';
import './Accordion.scss';

type OwnProps = {
    accordionTitle: string;
    score: string;
    children?: JSX.Element[];
};
export type AccordionProps = HTMLAttributes<{}> & OwnProps;

export const Accordion: React.FC<AccordionProps> = ({
    score,
    accordionTitle,
    children,
    ...props
}) => {
    const [collapse, setCollapse] = useState(true);

    return (
        <div className={`accordion ${!collapse && 'active'}`} {...props}>
            <div className="accordion-header" onClick={() => setCollapse(!collapse)}>
                <div className="accordion-title">
                    {collapse ? <ChevronBottom /> : <ChevronTop />}
                    {accordionTitle}
                </div>
                <div className="accordion-score">{score}</div>
            </div>
            {!collapse && <div className="accordion-body">{children}</div>}
        </div>
    );
};
